.search-header {
  font-size: 12px;
  padding: 2.5em 2em 1.5em 2em;
  text-transform: uppercase;
  margin-block-start: 0;
  margin-block-end: 0;

  &.main-title {
    font-weight: bold;
    color: #333;
  }

  &.hidden {
    display: none;
  }

}

.search-results {


  &.hidden {
    display:none;
  }

  &.mobile {
    position: absolute;
    top:4em;
    left: 0;
    width: 100%;
    background-color: rgba(255,255,255,.85);

    height: 100%;
    overflow-y: auto;

  }
  &.v {
    .results-col {
      margin-bottom: 2em;
    }
  }

  &.h {

    &.show {
      height: 100vh;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      right: 0;
      width: 80vw;
      overflow-y: auto;
      padding-top: 6em;
      background-color: rgb(255, 255, 255);
    }

    div.results-block {
      display: inline-block;
      vertical-align: top;
      margin-right: 5em;
      margin-bottom: 3em;
    }

    div.results-col {
      display: inline-block;

      width: 24em;
  }
  }

}