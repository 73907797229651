.search-item {
  padding: 0 0 0 0.8em;
  cursor: pointer;
  margin: .7em 0em .7em 1.8em;
  border-left: rgba(0, 0, 0, 0) solid 2px;
}

.search-item:hover,  .search-item:focus {
  border-left: #888 solid 2px;
  color: #222;
  img.director-icon, img.table-icon {
    opacity: 1;
  }
}

.search-item:focus {
  border-left: #030303 solid 2px;
  text-decoration: underline;
}

.search-suggestion-icon {
  display: inline-block;
  margin-right: .5em;
}

img.director-icon, img.table-icon {
  display: inline-block;
  width:2.5em;
  height:2.5em;
  vertical-align: middle;
  opacity: .8;
}