.IntroScreen, div.map-loading-screen {
    text-align: center;
    padding-top: 20vh;
    padding-bottom: 1em;
    width: 90vw;
    max-width: 500px;
    margin:auto;
}

div.introTextWrap {
    min-height: 6em;
    position:relative;
    font-size: 85%;
    .introText {
        position: absolute;
        margin:auto;
        opacity: 0;
        width: 100%;
        text-align: center;
        transition: opacity ease-in-out 400ms;
        user-select: none;

        &.introTextVisible {
            opacity: 1;
            &.subhead {
                opacity:.75;
            }
            transition-delay: 200ms;
        }

        &.mainhead {
            font-size: 2em;
        }

        &.subhead {
            font-size: 1.3em;
            font-weight: 400;
            line-height: 1.35em;
            margin-top: 0.35em;
        }

    }

    input.searchInput {
        width: calc(100% - 4em);
    }
}


div.map-loading-screen {
    width: 15em;
    user-select: none;
    div.introTextWrap {
        min-height: 6em;
    }
    div.introTextWrap.titleText {
        color: #030303;
        min-height: 2em;
    }
}

div.play-button {
    margin-top: 4em;
    font-size: 90%;
    user-select: none;
    cursor: pointer;
    opacity: 100;
    .play-icon {
        vertical-align: middle;
        margin-right: .6em;
        width: 2.5em;
        height: 2.5em;
        margin-top: -0.4em;
    }
    transition: all 300ms;
    &:hover, &:focus {
        filter: brightness(75%);
    }
    &.hidden {
        opacity: 0;
        pointer-events: none;
        cursor: default;
    }
}