Canvas {
  width: 100px;
  height: 100px;
}

.Home {
  font-size: 93.75%; // = 15px
  color: #555;
  overflow: hidden;
  height: 100%;
  width: 100%;

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-touch-callout: none;
  //-webkit-user-select: none;
  //-khtml-user-select: none;
  //-moz-user-select: none;
  //-ms-user-select: none;
  //user-select: none;

}

:focus {
  outline: none !important;
}

input[type="text"]
{
  background: transparent;
  border: none;
  font-size: inherit;
  font-family: 'IBM Plex Sans', sans-serif;
}

div.overlay-content {
  padding: 1.5em;
  .Chair {
    display: inline-block;
    vertical-align: middle;
    margin-right: 2em;
  }
}

.map-wrapper {
  width:100%;
  height: 100%;
  background-color: white;
  position: absolute;
  z-index: -1;
}


.stats {
  left: auto !important;
  right: 0px;
  top: auto !important;
  bottom: 0px;
  filter: grayscale(1);
}