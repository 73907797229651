.menu_wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 40em;
  min-height: 100vh;
  background-color: #ffffff;

  &.mobile {
    touch-action: pan-y;
    width: 100%;
  }

  .main_menu {
    width: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;

    .scrollableSections {
      flex: 1;
      overflow: auto;
    }

    .menu-close-icon {
      position: absolute;
      right: 1.5em;
      top: 1.5em;
    }

    .menu-title {
        width:100%;
        text-align: center;
        margin-top: 6em;
    }

    &.mobile {
      .menu-title {
        margin-top: 4.5em;
      }
    }

    .play-button {
      text-align: center;
      &:focus {
        color: #222;
        text-decoration: underline;
      }
    }

    .menu-section {
      margin-left: 3em;
      margin-right: 3em;
      margin-top: 4em;

      .section-title {
        margin-bottom: 1em;
        opacity:.9;
        text-transform: uppercase;
      }

      .section-desc {
        opacity:.8;
        margin-bottom: 1em;
      }

      .menu-link {
        margin-bottom: 1em;
        opacity:.8;
        user-select: none;

        a {
          color: inherit;
          text-decoration: none;
        }

        &.with-icon {
          display: flex;
          align-items: center;
          gap: 0.6em;
        }
        &:hover, &:focus {
          opacity: 1;
          cursor: pointer;
        }
        &:focus {
          color: #222;
          text-decoration: underline;
        }
        &:focus-within {
          opacity: 1;
        }
      }

      .menu-item {
        margin-bottom: 1em;
        opacity: .8;
        user-select: none;

        &.with-icon {
          display: flex;
          align-items: center;
          gap: 0.6em;
        }
      }

      .menu-sub-section {
        margin-left: 1.5em;
      }

      .print-sub-section {
        margin-left: 2.5em;
        p {
          opacity: .8;
        }
      }

      .menu-data-summary {
        opacity: .8;
        ul {
          margin-top: 0;
          padding-left: 1em;
        }
      }

      .expanded-section-content {
        padding-left:2.5em;
        margin-bottom: 2em;
      }

      .expanded-data-details-section {
        opacity: .8;
        padding-right: 2em;
        ul {
          margin-top: 0;
          padding-left: 1em;
        }
      }

      .saved-map-data-title {
        opacity: .8;
      }

      .data-details {
        padding-left: 2.1em;
        margin-top: .5em;
        margin-bottom: 1em;
        .details-link {
          font-size: 90%;
          cursor: pointer;
          display: flex;
          align-items: center;
          .icon {
            margin-left: .4em;
            width: 1em;
            height:1em;
            transform: rotate(0deg);
            transition: all 200ms ease-in-out;
          }
          &.open {
            .icon {
              transform: rotate(180deg);
            }
          }
          &.add-on {
            padding-left: 1.8em;
          }
        }
        .data-details-summary {
          font-size: 90%;
          padding: .5em 5em 1em 0;
          &.add-on {
            padding: .5em 5em 1em 1.8em;
          }
        }
      }

      .add-on-toggle .toggle-wrap {
        margin-bottom:0;
      }

      .add-on-toggle.saved-map {
        padding-left: .4em;
        .toggle-switch {
          display: none;
        }
        .data-details {
            padding-left: 0;
          .details-link.add-on {
            padding-left: 0;
          }
        }
      }

    }

    .find-connection-button-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      max-width: 21em;
    }

    .select-wrap {
      display: flex;
      align-items: center;
      width: 100%;
      align-content: space-evenly;
      justify-content: space-between;
      max-width: 21em;
      margin-bottom: 1.2em;
      .select-label {
        opacity: .8;
      }
      .tr-select {
        flex: auto;
        max-width: 15em;
      }
    }
  }
}

.find-connection {
  .find-connection-button-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    max-width: 21em;
  }

  .select-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    align-content: space-evenly;
    justify-content: space-between;
    max-width: 21em;
    margin-bottom: 1.2em;

    .select-label {
      opacity: .8;
    }
    .tr-select {
      flex: auto;
      max-width: 15em;
    }
  }
}

.note {
  font-size: 90%;
}