div.Chair {
  width: 40px;
  height: 60px;
  display: inline-block;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  filter: brightness(1);

  &:focus {
    filter: brightness(0.8);
  }

  &.sm {
    width: 24px;
    height: 36px;
  }

  &.lg {
    width: 48px;
    height: 72px;
  }

  &.menu {
      margin-left: calc(18.5em - 20px);
      transition: all 600ms ease-in-out;
      &.mobile {
        margin-left: calc(50% - 12px);
      }
  }

}