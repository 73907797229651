div.icon {
  display: inline-block;
  width:1.8em;
  height:1.8em;
  line-height: 0;

  &.interactive {
    cursor:pointer;
    &:hover {
      filter: brightness(0.8);
    }
    &:focus {
      filter: brightness(0.6);
    }
  }

  &.caret {
    transform: rotate(0deg);
    transition: all 200ms ease-in-out;
    &.open {
      transform: rotate(180deg);
    }
  }

  &.inline {
    display:inline-block;
  }

}