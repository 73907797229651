// Colors
$border-color: #888;
$disabled-color: #CCC; // background
$toggle-color: #888; // the toggle circle
$checked-color: #888; // the toggle circle
$focus-color: #222;
$opacity-normal: .9;
$opacity-light: .6;

.toggle-wrap {
  display: flex;
  align-items: center;
  margin: 1.2em 0;
  .toggle-source {
    margin-left: 4em;
  }
}

//This also applies to the toggle source fields on th radio
div.toggle-source {
  display: block;
  font-size: 90%;
  .toggle-source-name {
    font-style: italic;
    text-transform: capitalize;
  }
  .toggle-details-link {
    color: currentColor;
  }

}

.toggle-wrap label:focus-within {
 color: $focus-color;
  opacity: 1;
  span.toggle-label-text {
    text-decoration: underline;
  }
  .toggle-switch-label {
    border-color: $focus-color;
    .toggle-switch-switch {
      border-color: $focus-color;
      background-color: $focus-color;
    }
  }
}

.toggle-switch {
  position: relative;
  margin: .5em .75em .5em 0;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  &-checkbox {
    // display: none;
    position: absolute;
    opacity: 0;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1.6px solid $border-color;
    border-radius: 20px;
    margin: 0;
    &.disabled {
        border-color: $disabled-color;
        background-color: $disabled-color;
    }
    &:focus {
      outline: none;
    }
    > span:focus {
      outline: none;
    }
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      padding: 0;
      color: white;
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 10px;
    }
  }
  &-disabled {
    background-color: $disabled-color;
    cursor: not-allowed;
    &:before {
      background-color: $disabled-color;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 24px;
    margin: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
      background-color: $checked-color;
      opacity: $opacity-normal;
    }
  }
  &.small-switch {
    width: calc(44em / 15);
    min-width: calc(44em / 15);
    .toggle-switch-inner {
      &:after,
      &:before {
        content: "";
        height: calc(22em / 15); // 20px;
        line-height: calc(22em / 15);//20px;
      }
    }
    .toggle-switch-switch {
      width: calc(15em / 15);
      right: calc(18em / 15);
      margin: calc(5em / 15);
      background-color: $toggle-color;
      opacity: $opacity-light;
    }
  }

  transform: scale(0.825);
}