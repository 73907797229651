main.beta {
  display: flex;
  width: 100%;
  input[type="password"] {
    align-self: center;
    padding: 1em;
    margin: 45vh auto;
    border-radius: 2em;
    border: 1px solid #CCC;
  }
}
