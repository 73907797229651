.flyout-panel {

  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  .panel-content {
    position: absolute;
    top: 0;
    width: 80vw;
    min-height: 100vh;
    background-color: #ffffff;
    transition: box-shadow 200ms ease-in-out;
    &.left {
      left:0;
      box-shadow: 8em 0 20em 0em rgb(0 0 0 / 15%);
    }
    &.right {
      right:0;
      box-shadow: -8em 0 20em 0em rgb(0 0 0 / 15%);
    }
    &.closing {
      box-shadow: 0em 0 10em 0em rgb(0 0 0 / 0%);
    }
    .icon.back_right {
      position: absolute;
      right: 2em;
      top: 2em;
      z-index: 1000;
    }
  }

}
