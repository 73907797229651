.map canvas{
  background-color: #FFF;
}

div.mapLabelOuterWrapper {
  &.selected {
    z-index:10001 !important;
  }
  &.hovered {
    z-index:10001 !important;
  }
}

div.mapLabelWrapper {

  height:0;
  width:0;

  div.label {
    pointer-events: none;
    padding-top: 2.2px;
    transform: translate3d(-9.2em, 0, 0);
    text-align: center;
    color: #444;
    padding: 10px 15px;
    border-radius: 5px;
    user-select: none;
    width:16em;
    &.company {
      padding-top: 10px;
    }
    &.selected {
      color: #000;
      font-weight: 500;
      z-index: 200;
    }
  }

  div.delete, .icon.info_solid {
    display:inline-block;
    position: absolute;
    width: 2.5em;
    height: 2.5em;
    top: -4em;
    left: .5em;
    user-select: none;
    cursor:pointer;
    //background-color: deeppink;
    //border-radius: 50%;
    .icon {
      width: 100%;
      height: 100%;
    }
    &.selected {
      display: inline-block;
      &.dragged {
        display: none;
      }
    }
    &.mobile {
      top: -5em;
    }

  }

  img.rotate-arrows, img.rotate-arrows-guide {
    position: absolute;
    top: 2.1em;
    left: -2.75em;
    width: 5.6em;
    display: none;
    opacity: 0.8;
    user-select: none;
    &.selected {
      display: block;
      &.dragged {
        display: none;
      }
    }
  }
  img.rotate-arrows-guide {
    top: -2.8em;
    width: 11em;
    left: -5.5em;
    filter: sepia(5);
  }

  .screen-reader-only {
    width: 2em;
    height: 3.2em;
    position: absolute;
    //background-color: blue;
    opacity: 1;
    top: -2.7em;
    left: -1em;
    user-select: none;
    pointer-events:none;
  }

}

.map {
  height: 100%;
  width:100%;
  &.hover {
    cursor:pointer;
  }
}

.paused-wrapper {
  position:absolute;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  background-color: rgba(255,255,255,.9);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2em;

}



.end-of-path-wrapper {
  position:absolute;
  top:0;
  left:0;
  width:100vw;
  height:100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  gap: 2em;

  overflow:hidden;

  .eop-toast {

    p.title {
      text-transform: uppercase;
    }

    font-size: 85%;
    background-color: #555;
    color: #FFF;
    margin-bottom: -100em;
    padding:1em;
    border-radius: 1.5em;
    box-shadow: 0px 4px 4px 0px #00000040;
    width: 40em;
    transition: all 1000ms ease-in-out;

    &.show {
      margin-bottom: 2em;
    }

    &.mobile {
      width: calc(100vw - 6em);
    }

    .btn-wrap {
      text-align: right;
    }

  }
}

div.close-menu-overlay {
  width: 100vw;
  height:100vh;
  position:fixed;
  top:0;
  left:0;
}

div.saved-map-controls {
  div.loading-text {
    position: absolute;
    right: 2em;
    bottom: 2em;
  }
}

.top-rhs-wrap {
  position: absolute;
  top: 2em;
  right: 2em;
  display: flex;
  align-items: center;
  gap: .5em;
  width: calc(80vw - 1.5em - 0.8em);
  justify-content: stretch;

  &.mobile {
    top: 1.75em;
  }

  .spacer-left {
    flex:1;
    //background-color: cornflowerblue;
    transition: all 300ms ease-in-out;
    min-height: 1em;
    &.search-expanded {
      flex:0;
    }
  }

  .spacer-right {
    width: 1em;
    //background-color: coral;
    transition: all 300ms ease-in-out;
    min-height: 1em;
    &.search-expanded {
      flex:1;
    }
    &.mobile {
      width: .2em;
    }
  }

}

section.toasts {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  width: 100vw;
  flex-direction: column-reverse;
  gap:.5em;
  padding-bottom: 0.5em;
  overflow:hidden;
}