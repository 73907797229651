.topSearch {
  display: inline-block;
  vertical-align:middle;

  div.topSearchBar.desktop {
    //display: flex;
    //justify-content: stretch;
    //width: 25em;
    //margin-right: calc(80vw - 25em);
    height: 3em;
    border-radius: 1.5em;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    user-select: none;
    background-color: #FFF;
    opacity: 1;
    border: none;
    filter: drop-shadow(0 0 .1em rgba(0,0,0,.3));
    will-change: filter;
    transition: opacity 100ms ease-in-out, filter 100ms ease-in-out;


    div.icon {
      vertical-align: middle;
      margin: .6em;
    }

    div.input-wrap {
      display:inline-block;
      width: min(45vw, 25em);
      transition: width 10ms ease-out;
    }

    input[type="text"] {
      padding: .75em;
      width: 100%;
      outline: none;
    }

    &.hidden {
      transition: max-width 80ms ease-in-out, opacity 100ms ease-in-out, filter 100ms ease-in-out;
      // opacity: 0;
      filter: drop-shadow(0 0 0 rgba(0,0,0,0));

      input[type="text"] {
        display:none;
      }

      div.input-wrap {
        width: 0;
      }

      div.icon.close {
        display:none;
      }

    }

    &:hover {
      filter: drop-shadow(0 0 1em rgba(0,0,0,.15));
    }

    &.focused {
      // height: 30em;
    }

  }

  .topSearchBar.mobile {
    position: fixed;
    margin: 0 1em;
    top: 0;
    left: 0;
    width: calc(100% - 2em);
    background-color:#FFF;
    padding: .75em 0;
    box-sizing: border-box;
    border-bottom: 1px solid #CCC;
    z-index:100;

    div.icon {
      vertical-align: middle;
      margin: .6em;
    }

    .input-wrap {
      width: calc(100% - 8em);
      display: inline-block;
    }

    input[type="text"] {
      padding: .75em;
      width: 100%;
      outline: none;
      vertical-align: middle;
    }

    &.hidden {
      display: none;
    }

  }

  .mobileSearchIcon {
    //position: absolute;
    //top: 1.5em;
    //right: 2em;

    div.icon {
      width: 2em;
      height: 2em;
    }
  }


}