.btn {
  padding: 1em 1.5em;;
  background-color: #FFF;
  color: #888;
  border: 1px solid #888;
  border-radius:5px;
  display:inline-block;
  text-transform: uppercase;
  width: max-content;
  transition: all 300ms;
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;

  .icon {
    width: 1.4em;
    height: 1.4em;
    vertical-align: middle;
    margin-top: -0.2em;
    margin-right: 0.4em;
    filter: brightness(130%);
    transition: all 300ms;
  }

  &:hover, &.mobile {
    border: 1px solid #555;
    color: #555;
    .icon {
      filter: brightness(90%);
    }
  }

  &:focus  {
    border: 1px solid #222;
    color: #222;
    .icon {
      filter: brightness(80%);
    }
  }

  &.progress {
    background-color: #DEDEDE;
    &.dark {
      background-color: rgba(0,0,0,0);
    }
  }

  .progress-bar {
    height: 100%;
    background-color: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px 0 0 4px;
    &.complete {
      border-radius: 4px;
    }
  }

  &.disabled {
    color: #CCC;
    border: 1px solid #CCC;
    cursor: default;
  }

  &.dark {
    color:#DDD;
    border: 1px solid #DDD;
    background-color: rgba(0,0,0,0);
    &:hover, &.mobile {
      border: 1px solid #FFF;
      color: #FFF;
      .icon {
        filter: brightness(200%);
      }
    }
    .progress-bar {
      background-color: #777;
    }
  }

  &.tertiary {
    border:none;
    text-decoration: underline;
    &:hover {
      border:none;
    }
    &.dark {
      border:none;
    }
  }

  span {
    position:relative;
  }

}


.tr-select {

  .tr-sel__input-container {
    padding: .75em 0;
  }

  .tr-sel__value-container {
    padding-left: .75em;
  }

  .tr-sel__single-value {
    color: currentColor;
    opacity: .8;
    padding-left: 0em;
  }

  .tr-sel__control {
    border-radius: 5px;
    border: #888 solid 1px;
    &.tr-sel__control--is-focused {
      border: #888 solid 1px;
      box-shadow: 0 0 0 1px #888;
    }
  }

  span.tr-sel__indicator-separator {
    display: none;
  }

  svg {
    /* stroke: #888; */
    color: #888;
    opacity: .8;
  }

  .tr-sel__option {
    color:#888;
    &.tr-sel__option--is-focused {
      color:#888;
      background-color:#EEE;
    }
    &.tr-sel__option--is-selected {
      color:#fff;
      background-color:#888;
    }
  }

}

