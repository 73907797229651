@import "../../../App.scss";

.toggle-pr-wrap {
  position: relative;
  display:inline-block;
  border: 1.5px solid $icon-color;
  width: 3.2em;
  height:1.8em;
  border-radius: 2em;
  .icon {
    position:absolute;
    width: 1.1em;
    height: 1.1em;
    top:.35em;
    &.rotate_arrows {
      right:.34em;
    }
    &.pan_arrows {
      left:.34em;
    }
  }
  .toggle-pr-circle {
    position:absolute;
    top:.2em;
    right:1.6em; // .2em
    width: 1.4em;
    height: 1.4em;
    background-color: $icon-color;
    border-radius: 2em;
    transition: right .15s ease-in-out;
  }
  &.rotate {
    .toggle-pr-circle {
      right: .2em;
    }
  }
  &:focus {
    border: 1.5px solid $focus-color;
  }
}

.toggle-pr-and-lock-wrap {
  .icon.lock {
    position: relative;
    height: 1.4em;
    width: 1.4em;
    vertical-align: super;
    margin-left: 0.5em;
  }
}
