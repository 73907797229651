.toast_wrap {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  left: 0;
  //overflow: hidden;
  height: 100%;
  pointer-events: none;

  .toast {
    position: relative;
    font-size: 85%;
    background-color: #555;
    color: #FFF;
    padding:1em;
    border-radius: 1.5em;
    box-shadow: 0px 4px 4px 0px #00000040;
    width: 40em;
    pointer-events: all;
    p.title {
      text-transform: uppercase;
    }
    .close-toast {
      position: absolute;
      right: 1em;
      top: 0.8em;
    }

    .toast_body {
      padding-right: 1.75em;
    }

  }

  &.mobile {
    .toast {
      width: calc(100% - 6em);
    }
  }

}