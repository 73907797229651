div.dialog-wrap {
  position: fixed;
  top:0;
  left:0;
  width:100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;

  .dialog-bg {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }

  .dialog {
    background-color: #FFFFFF;
    width: 90%;
    max-width: 25em;
    position: relative;
    border-radius: 1em;
    padding: 1em;
    box-sizing: border-box;
    filter: drop-shadow(0.1em 0.1em 2em rgba(0,0,0,.5));

    .icon {
      position: absolute;
      right: 1em;
      top: 1em;
    }

    .dialog-title {
      text-transform: uppercase;
      margin: 0.5em 0 0.75em 0;
    }

    .dialog-body {
      margin-bottom: 1em;
    }

    .dialog-buttons {
      text-align: right;
    }

  }

}