.info-close-panel-bg {
  position: absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
}


.info_panel_wrap {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  left: 0;
  overflow: hidden;
  height: 100%;
  pointer-events: none;
  touch-action: none;

  .info_panel {
    background-color: #FFF;
    padding:1em;
    width: 40em;
    max-width: 40em;
    box-sizing: border-box;
    min-height: 100vh;
    position:relative;
    box-shadow: -8em 0 20em 0em rgb(0 0 0 / 15%);
    height: 100%;
    pointer-events: all;

    &.full_info_page {
      width: 100%;
    }

    p.title {
      text-transform: uppercase;
    }
    .close_info_panel {
      position: sticky;
      right: 1em;
      top: 1em;
      text-align: end;
      z-index: 1000;
    }
    .info_panel_body {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height:100%;
      box-sizing: border-box;

      .info-data-content-wrap {

        position: absolute;
        height:100%;
        top: 0;
        left: 0;
        width: 100%;
        padding:1em;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .info-header {
          // position: sticky; // unnecessary because Flex takes care: https://stackoverflow.com/questions/47659664/flexbox-with-fixed-header-and-footer-and-scrollable-content
          display: flex;
          width: calc(100% - 3em);
          gap: 1em;
          padding: 1em 0;

          .name-gender-age-box {
            flex: 1;
            .info-gender {
              text-transform: capitalize;
            }
          }

          .info-image {
            width: 6em;
            height:6em;
            overflow: hidden;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;
            background-color: #EFEFEF;
            background-blend-mode: luminosity;
            &.info-logo {
              background-size: contain;
              background-position: center center;
              background-color: #FFF;
              height:3em;
            }
          }

          .info-title {
            color: #000;
            text-transform: uppercase;
          }

          .info-alias {
            font-style: italic;
          }

        }

        .info-content {
          flex: 1;
          overflow: auto;
          padding-right: 2em;
          padding-bottom: 6em;
          touch-action: pan-y;

          .info-section {
            margin-top: 1em;

            .wikipedia-summary {
              display: inline-block;
            }

            &.name-value {
              display: flex;
              gap:1em;

              .info-name {
                width: 30%;
                &.wikidata-attribution {
                  width:auto;
                }
              }
              .info-value {
                flex: 1;
                &.info-link {
                  cursor: pointer;
                }
              }
              .info-school, .more-info-link {
                margin-bottom: .75em;
                :last-child {
                  margin-bottom: 0em;
                }
              }
              ul.info-edu-info {
                margin: 0;
                padding-left: 1.2em;
              }
              .info-majors {
                text-transform: capitalize;
              }

            }

            a {
              color: #555;
              &:focus {
                color: #111;
              }
            }

          }

        }
      }

      &.full_info_page {
        .info-data-content-wrap {
          max-width: 50em;
          position: relative;
          margin: auto;
        }
      }


    }
  }

  &.mobile {
    .info_panel {
      width: 100%;
      touch-action: pan-y;
    }
  }

  .wikidata-image {
    height: 3em;
    width: 3em;
    background-image: url(https://upload.wikimedia.org/wikipedia/commons/6/66/Wikidata-logo-en.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-blend-mode: luminosity;
    background-color: #FFFFFF;
    display: inline-block;
    margin-top: 0.3em;
  }
  a.wikidata-attribution {
    text-decoration: none;
  }

  .info-rev-graph {
    padding-top: .5em;
    width: 10em;
    height: 2.5em;
  }

  .info-page-header {
    max-width: 50em;
    padding: 1em;
    margin: auto;
    box-sizing: border-box;
    .info-page-logo {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      font-size: 85%;
    }
    a {
      text-decoration:none;
    }
  }

}