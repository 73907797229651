div.searchBar {
  width: 100%;
  height: 3em;
  border-radius: 1.5em;
  border: solid 1px #BBB;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  user-select: none;
  background-color: #FFF;
  transition: all ease-in-out 150ms;

  &.hidden {
    opacity: 0;
    pointer-events: none;
    cursor: default;
  }

  & div.icon {
    vertical-align: middle;
    margin: .6em;
  }

  & input[type="text"] {
    padding: .75em;
    width: calc(100% - 6em);
    outline:none;
  }

  &.desktop {
    border: none;
    filter: drop-shadow(0 0 .1em rgba(0,0,0,.3));
    will-change: filter;
    overflow: hidden;

    & input[type="text"] {
      padding: 0.8em 0.75em 0.75em 0.75em;
    }

  }

  &:hover {
    filter: drop-shadow(0 0 1em rgba(0,0,0,.15));
  }

  &.focused {
    height: 34em;
  }

  & div.divider {
    width: 95%;
    height:1px;
    background-color: #DDD;
    margin:auto;
  }

  div.suggested{
    display: none;
    &.focused {
      display:block;
    }

  }

}