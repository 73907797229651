.App {
  font-family: 'Inter', sans-serif;
  /*font-family: 'IBM Plex Sans', sans-serif;*/
  -webkit-font-smoothing: subpixel-antialiased;
}

.fine-print {
  font-size: 90%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  color: #000;
  text-decoration: underline;
}


html { margin-left: calc(100vw - 100%); } //https://css-tricks.com/elegant-fix-jumping-scrollbar-issue/

body {
  margin:0;
  background-color: #FFF;
}

.screen-reader-only {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
  pointer-events:none;
  opacity:0;
}

.capitalize {
  text-transform: capitalize;
}

h1, h2, h3, h4 {
  font-weight: 400;
}

.read-more {
  font-size: 1.2vw;
}


$icon-color: #888;
$focus-color: #333;