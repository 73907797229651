$radio-color: #888;
$opacity-normal: .9;

input[type=radio] {
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(255,255,255,0);
  margin: 0.5em 0.6em 0.5em 0em;
  font: inherit;
  color: currentColor;
  width: calc(19em / 14);
  height: calc(19em / 14);
  border: 1.5px solid $radio-color;
  border-radius: 50%;
  transform: translateY(-0.1em);
  place-content: center;
  display: inline-grid;
  vertical-align: middle;
  opacity: $opacity-normal;
}

input[type="radio"]::before {
  content: "";
  width: .7em;
  height: .7em;
  border-radius: 50%;
  background-color: $radio-color;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  opacity: $opacity-normal;
}

input[type="radio"]:checked::before {
  transform: scale(1);
  opacity: $opacity-normal;
}

input[type="radio"]:focus {
  outline: max(4px, 0.25em) solid $radio-color;
  outline-offset: max(4px, 0.25em);
  opacity: $opacity-normal;
}

label.radio-button:focus-within {
  opacity: 1;
  .radio-button-label-container {
    opacity: 1;
    text-decoration: underline;
  }
}

.radio-button-label-container {
  display:inline-block;
  vertical-align: text-top;
}




//input[type=radio] {
//  -webkit-appearance: none;
//  appearance: none;
//  background-color: rgba(255,255,255,0);
//  margin: 0.5em 0.6em 0.5em 0em;
//  font: inherit;
//  color: currentColor;
//  width: calc(21em / 14);
//  height: calc(21em / 14);
//  border: 1px solid currentColor;
//  border-radius: 50%;
//  transform: translateY(-0.1em);
//  place-content: center;
//  display: inline-grid;
//  vertical-align: middle;
//}
//
//input[type="radio"]::before {
//  content: "";
//  width: .9em;
//  height: .9em;
//  border-radius: 50%;
//  background-color: currentColor;
//  transform: scale(0);
//  transition: 120ms transform ease-in-out;
//}
//
//input[type="radio"]:checked::before {
//  transform: scale(1);
//}
//
//input[type="radio"]:focus {
//  outline: max(2px, 0.15em) solid #000;
//  outline-offset: max(2px, 0.15em);
//}
//
//.radio-button-label-container {
//  display:inline-block;
//  vertical-align: text-top;
//}